// @flow
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCurrentCli, getCurrentUserRoles, getCurrentUsername, getCurrentTeams } from "../services/Auth/auth.utils";
import AuthService from "../services/Auth/auth.service";
import { getParam, isPublic } from '../components/Utils/Utils';

const local = window.location.origin.includes("localhost") ? "LOCAL" : window.location.origin.includes("tomazapp.com.br") ? "TOMAZ" : "VIRTUALAWYER";

function backend() {
  return {
    path: local == "LOCAL" ? "http://localhost" : local == "VIRTUALAWYER" ? "https://virtualawyer.com.br" : "https://tomazapp.com.br",
    port: local == "LOCAL" ? ":8762" : ""
  }
} 

export function getURI(endpoint) {
  let suffix = (endpoint.startsWith("/") ? endpoint : "/" + endpoint);
  if (isPublic())
    suffix = suffix.replace("/api", "/api/public");
  return appConfig.BASE_URL_SERVICES + suffix;
}

export function mountPayload() {
  if (isPublic()) {
    return {  
      cli: getParam("cli")
    }
  }
  return {
    cli: getCurrentCli(),
    roles: getCurrentUserRoles(),
    author: getCurrentUsername(),
    teams: getCurrentTeams()
  }
}

export function handleReturn(ret) {
  if (ret[0]?.status < 200 || ret[0]?.status >= 300) {
    if (ret[0]?.message) {
      console.error(ret[0]?.message)
      toast.error(ret[0]?.message, {toastyId:"id_curr_error", closeOnClick: true, onClose: ()=>{
        if (ret[0]?.status == 401 || ret[0]?.message == "Usuário não encontrado!") {
          AuthService.logout();
        }
      }});
    } else {
      console.error("Ocorreu um erro. Entre em contato com nosso suporte.")
    }
  }
  return ret[1]; 
}

export const appConfig = Object.freeze({
  CLIENT_ID: "virtualawyer-cli",
  SECRET: "10192830f8n.23pf2903",
  APP_NAME: 'virtualawyer',
  DEV_MODE: false,
  DEBUG_ENABLED: false,
  FAKE_ASYNC_DELAY: 0,
  BASE_URL_SERVICES: backend().path + backend().port,
  BASE_URL_ROOT: backend().path + backend().port + "/api/auth",
  COLOR_SCHEME: "navy", // "mix", // "cream", // 
  COLORS: {
    headerPanelForeground: "#38425d",
    menuHeaderBackColor: "#283744",
    logoBackgroundColor: "#F9F9F9",
    logoForegroundColor: "#000",
  },
  // #region API configs
  earningGraph: {
    data: {
      API: 'api/earnigGraphData',
    },
  },
  teamMates: {
    data: {
      API: 'api/teamMates',
    },
  },
  userInfos: {
    data: {
      API: 'api/userInfos',
    },
  },
  // #endregion
});
