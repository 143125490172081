const Aux = (props) => props.children;

export default Aux;

//import { ToastContainer } from 'react-toastify';

/*
const Aux = (props) => <>
	<ToastContainer/>
	{props.children}
</>
*/
