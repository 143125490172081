import React from 'react';
import { mountPayload, getURI } from '../config/api';
import { toast } from 'react-toastify';
import authHeader from "./Auth/auth.header";
import AuthService from "./Auth/auth.service";
import 'react-toastify/dist/ReactToastify.css';

const HeaderJson = () => ({
	"Content-Type": "application/json;charset=UTF-8",
	...authHeader()
})

function hasError(data) {
	if (data == null)
		return false;
	if (data.error != undefined) {
		if (data.status == 401 || data.error == "Usuário não encontrado!") {
			AuthService.logout();
		}
		console.error(data.error);
	    return true;
	}
	return false;
}

export async function API_PUBLIC_POST(endpoint, obj) {
	return API_POST_CORE(endpoint, obj, false);
}

export async function API_POST(endpoint, obj) {
	return API_POST_CORE(endpoint, obj, true);
}

async function FETCH(method, endpoint, obj) {
	let result = null;
	if (obj == null && endpoint == null) {
		endpoint = method;
		result = await fetch(endpoint, { method: 'GET' });
	} else if (obj == null)
		result = await (fetch(getURI(endpoint), {
		    method: method,
		    headers: HeaderJson()
		}));
	else
		result = await (fetch(getURI(endpoint), {
		    method: method,
		    body: JSON.stringify(obj),
		    headers: HeaderJson()
		}));
	return result;
}

async function API_POST_CORE(endpoint, obj, protect) {
	try {
		let response = await FETCH('POST', endpoint, obj);
		let data = await response.json();
		return protect && hasError(data) ? [data, {}] : await [response, data];
	} catch (error) {
		if (error == "TypeError: Failed to fetch")
			toast.error("Servidor offline!", {toastyId:"id_no_server", closeOnClick: true, onClose: ()=>{window.location.href='/login'}});
		console.error("Não conseguiu postar informações via API POST: " + error);
	}
    return [{status:500, message: "Servidor offline!"}, {}];
}

export async function API_PUBLIC_BLOB_POST(endpoint, obj) {
	return API_POST_BLOB_CORE(endpoint, obj, false);
}

export async function API_POST_BLOB(endpoint, obj) {
	return API_POST_BLOB_CORE(endpoint, obj, true);
}

async function API_POST_BLOB_CORE(endpoint, obj, protect) {
	try {
		let response = await FETCH('POST', endpoint, obj);
		let data = await response.blob();
		return protect && hasError(data) ? [data, {}] : await [response, data];
	} catch (error) {
		if (error == "TypeError: Failed to fetch")
			toast.error("Servidor offline!", {toastyId:"id_no_server", closeOnClick: true, onClose: ()=>{window.location.href='/login'}});
		console.error("Não conseguiu postar informações via API POST: " + error);
	}
    return [{status:500, message: "Servidor offline!"}, {}];
}

export async function API_PUBLIC_BASE64_POST(endpoint, obj) {
	return API_POST_BASE64_CORE(endpoint, obj, false);
}

export async function API_POST_BASE64(endpoint, obj) {
	return API_POST_BASE64_CORE(endpoint, obj, true);
}

async function API_POST_BASE64_CORE(endpoint, obj, protect) {
	try {
		let response = await FETCH('POST', endpoint, obj);
		let arrayBuffer = await response.arrayBuffer();
		let data = btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)));
		return protect && hasError(data) ? [data, {}] : await [response, data];
	} catch (error) {
		if (error == "TypeError: Failed to fetch")
			toast.error("Servidor offline!", {toastyId:"id_no_server", closeOnClick: true, onClose: ()=>{window.location.href='/login'}});
		console.error("Não conseguiu postar informações via API POST: " + error);
	}
    return [{status:500, message: "Servidor offline!"}, {}];
}

export async function API_DELETE(endpoint) {
	try {
		let response = await FETCH('DELETE', endpoint);
		let data = await response.json();
		return hasError(data) ? [data, {}] : await response;
	} catch (error) {
		if (error == "TypeError: Failed to fetch")
			toast.error("Servidor offline!", {toastyId:"id_no_server", closeOnClick: true, onClose: ()=>{window.location.href='/login'}});
		console.error("Não conseguiu deletar via API DELETE: " + error);
	}
}

export async function API_GET_ABSOLUTE(url) {
	try {
		let response = await FETCH(url);
		let data = await response.json();
		return hasError(data) ? [data, {}] : await [response, data];
	} catch (error) {
		console.error(error + "\nNão conseguiu encontrar informações via API GET: " + error);
	}
    return [{status:500, message: "Servidor offline!"}, {}];
}

export async function API_GET(endpoint) {
	try {
		let response = await FETCH('GET', endpoint);
		let data = await response.json();
		return hasError(data) ? [data, {}] : await [response, data];
	} catch (error) {
		console.error(error + "\nNão conseguiu encontrar informações via API GET: " + error);
	}
    return [{status:500, message: "Servidor offline!"}, {}];
}

export async function API_GET_BLOB(endpoint) {
	try {
		let response = await FETCH('GET', endpoint);
		let data = await response.blob();
		return hasError(data) ? [data, {}] : await [response, data];
	} catch (error) {
		console.error(error + "\nNão conseguiu encontrar informações via API GET: " + error);
	}
    return [{status:500}, {}];
}

export async function API_PUT(endpoint, obj) {
	try {
		let response = await FETCH('PUT', endpoint, obj);
		let data = await response.json();
		return hasError(data) ? [data, {}] : await [response, data];
	} catch (error) {
		if (error == "TypeError: Failed to fetch")
			toast.error("Servidor offline!", {toastyId:"id_no_server", closeOnClick: true, onClose: ()=>{window.location.href='/login'}});
		console.error("Não conseguiu atualizar informações via API PUT: " + error);
	}
    return [{status:500, message: "Servidor offline!"}, {}];
}

export async function API_PATCH(endpoint, obj) {
	try {
		let response = await FETCH('PATCH', endpoint, obj);
		let data = await response.json();
		return hasError(data) ? [data, {}] : await [response, data];
	} catch (error) {
		if (error == "TypeError: Failed to fetch")
			toast.error("Servidor offline!", {toastyId:"id_no_server", closeOnClick: true, onClose: ()=>{window.location.href='/login'}});
		console.error("Não conseguiu atualizar informações via API PATCH: " + error);
	}
    return [{status:500, message: "Servidor offline!"}, {}];
}