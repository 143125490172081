import React, { Component, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import { isPublicURL } from "../components/Utils/Utils";
import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../routes/route";
import '../../node_modules/font-awesome/scss/font-awesome.scss';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

class App extends Component {
    
    renderMenu() {
      return routes.map((route, index) => {
          return (route.component) ? (
              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                      <route.component {...props} />
                  )} />
          ) : (null);
        });
    }

    render() {
      if (!localStorage.user && !isPublicURL())
        window.location.assign("/login");
      return <Aux>
          <ScrollToTop>
              <Suspense fallback={<Loader/>}>
                  <Switch>
                      {this.renderMenu()}
                      <Route path="/" component={AdminLayout} />
                  </Switch>
              </Suspense>
          </ScrollToTop>
      </Aux>;
    }
}

export default App;
