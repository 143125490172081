import React from 'react';

const SignIn = React.lazy(() => import('../views/Authentication/SignIn/Default'));
const SignUp = React.lazy(() => import('../views/Authentication/SignUp/Default'));
const RecoverPassword = React.lazy(() => import('../views/Authentication/RecoverPassword/Default'));

const route = [
    { path: '/signup', exact: true, name: 'Sign-up', component: SignUp },
    { path: '/recover-pass', exact: true, name: 'Recuperar Senha', component: RecoverPassword },
    { path: '/signin', exact: true, name: 'Log-in', component: SignIn }
];

export default route;