import * as actionTypes from './actions';
import config from './../config/config';

const initialState = {
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    ...config,
    isFullScreen: false, // static can't change
};

const reducer = (state = initialState, action) => {
    let trigger = [];
    let open = [];

    switch (action.type) {
        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: !state.collapseMenu
            };
        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
                trigger = (triggerIndex === -1) ? [action.menu.id] : [];
                open = (triggerIndex === -1) ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_COLLAPSE_LEAVE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return {...state};
        case actionTypes.FULL_SCREEN :
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout
            };
        /* MEUS REDUCERS */
        case actionTypes.EXPORT_EXCEL:
            return {
                ...state,
                excelTable: exportToExcel(action)
            };
        case actionTypes.REGISTER_URL:
            return {
                ...state,
                url: action.url
            };
        case actionTypes.ITEMS_LIST:
            return {
                ...state,
                kind: action.kind, 
                options: action.options
            };
        case actionTypes.ITEMS_CURRENT:
            return {
                ...state,
                chosen: action.chosen
            };
        case actionTypes.SUBMIT_SEARCH:
            return {
                ...state,
                search: action.search
            };        
        case actionTypes.FLIP_PREVIEW:
            return {
                ...state,
                preview: !state.preview
            };
        case actionTypes.SHARE_LINK:
            window.open(action.url, "_blank");
            return state;

        default:
            return state;
    }
};

function exportToExcel(action) {
    const form = action.table;
    const multiDataSet = [
        {
            columns: [
                {title: "Headings", width: {wpx: 80}},//pixels width 
                {title: "Text Style", width: {wch: 40}},//char width 
                {title: "Colors", width: {wpx: 90}},
            ],
            data: [
                [
                    {value: "H1", style: {font: {sz: "24", bold: true}}},
                    {value: "Bold", style: {font: {bold: true}}},
                    {value: "Red", style: {fill: {patternType: "solid", fgColor: {rgb: "FFFF0000"}}}},
                ],
                [
                    {value: "H2", style: {font: {sz: "18", bold: true}}},
                    {value: "underline", style: {font: {underline: true}}},
                    {value: "Blue", style: {fill: {patternType: "solid", fgColor: {rgb: "FF0000FF"}}}},
                ],
                [
                    {value: "H3", style: {font: {sz: "14", bold: true}}},
                    {value: "italic", style: {font: {italic: true}}},
                    {value: "Green", style: {fill: {patternType: "solid", fgColor: {rgb: "FF00FF00"}}}},
                ],
                [
                    {value: "H4", style: {font: {sz: "12", bold: true}}},
                    {value: "strike", style: {font: {strike: true}}},
                    {value: "Orange", style: {fill: {patternType: "solid", fgColor: {rgb: "FFF86B00"}}}},
                ],
                [
                    {value: "H5", style: {font: {sz: "10.5", bold: true}}},
                    {value: "outline", style: {font: {outline: true}}},
                    {value: "Yellow", style: {fill: {patternType: "solid", fgColor: {rgb: "FFFFFF00"}}}},
                ],
                [
                    {value: "H6", style: {font: {sz: "7.5", bold: true}}},
                    {value: "shadow", style: {font: {shadow: true}}},
                    {value: "Light Blue", style: {fill: {patternType: "solid", fgColor: {rgb: "FFCCEEFF"}}}}
                ]
            ]
        }
    ];
    var table = [
        {
            columns: [],
            data: []
        }
    ];

    const maxChars = getMaxChars(form);
    let j = 0;
    for (let h in form.head) 
        table[0].columns.push({title: form.head[h], width: {wch: maxChars[j++]}});
    for (let b in form.body) {
        let row = [], i = 0;
        for (let c in form.body[b])
            row.push({value: form.body[b][c], style: {alignment: {wrapText: true}}});
        table[0].data.push(row);
    }
    return table;
}

function getMaxChars(form) {
    var maxChars = [], limit = 256;
    for (let b in form.body) {
        let i = 0;
        for (let c in form.body[b])  {
            const value = form.body[b][c];
            if (value.includes("\n")) {
                let pieces = value.split("\n");
                let maxLen = 0;
                for (let w in pieces)
                    if (maxLen < pieces[w].length)
                        maxLen = pieces[w].length;
                maxChars[i] = maxChars[i] == undefined ? maxLen : Math.max(maxChars[i], maxLen);
            } else if (value.length > 256)
                maxChars[i] = 256;
            else
                maxChars[i] = maxChars[i] == undefined ? value.length : Math.max(maxChars[i], value.length);
            i++;
        }
    }
    let j = 0;
    for (let h in form.head) {
        maxChars[j] = Math.max(form.head[h].length, maxChars[j]);
        j++;
    }
    return maxChars;
}

export default reducer;