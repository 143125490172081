export function hasPermission(cred) {
	if (!localStorage.user)
		return false;
  	const user = JSON.parse(localStorage.getItem('user'));
	return user.roles.includes(cred);
}

export function getCurrentUser() {
	if (!localStorage.user)
		return null;
  	return JSON.parse(localStorage.getItem('user'));
}

export function setCurrentUser(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

export function getCurrentUsername() {
  const user = getCurrentUser();
  return user?.username;
}

export function getCurrentUserCli() {
  const user = getCurrentUser();
  return user?.cli;
}

export function getCurrentUserRoles() {
  const user = getCurrentUser();
  return user?.roles;
}

export function getCurrentTeams() {
  const user = getCurrentUser();
  return user?.teams;
}

export function getCurrentCli() {
  return localStorage.getItem("overcli") != null && localStorage.getItem("overcli") != "" ? localStorage.getItem("overcli") : getCurrentUserCli();
}

export function setCurrentCli(cli) {
  return localStorage.setItem("overcli", cli);
}

